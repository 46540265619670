<template>
    <b-card title="Edit Category">
        <validation-observer ref="simpleRules">
            <b-form @submit.prevent>
                <b-row>
                    <!-- Name -->
                    <b-col cols="12">
                        <b-form-group label="Name" label-for="name">
                            <validation-provider name="Name" rules="required|max:20" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="UserIcon" />
                                    </b-input-group-prepend>

                                    <b-form-input id="name" v-model="item.name"
                                        :state="errors.length > 0 ? false : null" placeholder="Name" />
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- <b-col cols="12">
                        <b-form-group label="Publish / Unpblish category for search" label-for="publish">
                            <validation-provider name="Last Name" rules="required|max:20" #default="{ errors }">
                                  <select class="form-control"  @change="change" >
                                                   <option :value="1" :selected="true">Published</option>
                                                   <option v-for="option in options" :key="option" v-bind:value="option.value" >{{ option.text }}</option>
                                                  </select>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col> -->

                    <b-col cols="12">
                        <b-form-group label="Publish / Unpblish category for search" label-for="publish">
                            <validation-provider name="publish" rules="required" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="MapPinIcon" />
                                    </b-input-group-prepend>
                                    <b-form-select id="publish" v-model="item.type" :options="options" :state="errors.length > 0 ? false : null">
                                    </b-form-select>
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- meta_title -->
                    <b-col cols="12">
                        <b-form-group label="Description" label-for="description">
                            <validation-provider name="description" rules="required|max:20" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="BoxIcon" />
                                    </b-input-group-prepend>
                                    <!-- <b-form-input id="fh-description" v-model="item.description" type="description"
                                        :state="errors.length > 0 ? false : null" placeholder="description" /> -->
                                        <b-form-textarea id="description" v-model="item.description" 
                                        :state="errors.length > 0 ? false : null" placeholder="Enter something..." rows="5" size="lg" max-rows="10"></b-form-textarea>
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                            <!-- <b-form-text class="text-danger">
                                Enter a Valid description
                            </b-form-text> -->
                        </b-form-group>
                    </b-col>

                    <!-- Profile Preview -->

                    <b-col cols="12">
                        <b-img thumbnail fluid :src="previewImg" :class="previewImg.length <= 0 ? 'd-none' : null"
                            alt="Image"></b-img>
                    </b-col>

                    <!-- Profile Image -->
                    <b-col cols="12">
                        <b-form-group label="Profile Image" label-for="profile">
                            <validation-provider name="Profile Image" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="ImageIcon" />
                                    </b-input-group-prepend>
                                    <b-form-file id="profile" v-model="profileImg" :state="errors.length > 0 ? false : null"
                                        placeholder="Choose a file or drop it here..."
                                        drop-placeholder="Drop file here..." />
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <b-col cols="12">
                        <b-form-group label="Meta Title" label-for="meta title">
                            <validation-provider name="meta_title" rules="required|max:20" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="UserIcon" />
                                    </b-input-group-prepend>

                                    <b-form-input id="meta_title" name="meta_title" v-model="item.meta_title"
                                        :state="errors.length > 0 ? false : null" placeholder="Meta Title" />
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <b-col cols="12">
                        <b-form-group label="Meta Description" label-for="meta description">
                            <validation-provider name="meta_description" rules="required|max:20" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="BoxIcon" />
                                    </b-input-group-prepend>

                                    <b-form-textarea id="meta_description" name="meta_description" v-model="item.meta_description" placeholder="Enter something..." rows="5" size="lg" max-rows="10"></b-form-textarea>
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <b-col cols="12">
                        <b-form-group label="Meta Keyword" label-for="meta Keyword">
                            <validation-provider name="meta_keywords" rules="required|max:20" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="UserIcon" />
                                    </b-input-group-prepend>

                                    <b-form-input id="meta_keywords" name="meta_keywords" v-model="item.meta_keywords"
                                        :state="errors.length > 0 ? false : null" placeholder="Meta Keyword" />
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>


                    <!-- submit and reset -->
                    <b-col>
                        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit"
                            @click.prevent="validationForm" variant="primary" class="mr-1">
                            Submit
                        </b-button>
                        <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-secondary"
                            @click="reset">
                            Reset
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
    </b-card>
</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BFormText,
    BFormDatalist,
    BFormInvalidFeedback,
    BInputGroup,
    BInputGroupPrepend,
    BFormValidFeedback,
    BFormFile,
    BFormSelect,
    BFormTextarea,
    BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
//Database
import { db, storage } from "@/main";
import firebase from "firebase";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

//Vee-Validate 
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import {
    required
} from '@validations'

export default {
    components: {
        BCard,
        BRow,
        BCol,
        BFormGroup,
        BInputGroup,
        BInputGroupPrepend,
        BFormInput,
        BFormCheckbox,
        BForm,
        BFormText,
        BButton,
        BFormDatalist,
        BFormInvalidFeedback,
        BFormValidFeedback,
        BFormFile,
        BFormSelect,
        BFormTextarea,
        BImg,
        ValidationProvider,
        ValidationObserver,
        required,
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            item:{},
            selectedFlavor: '',
            selc:'',
            type: null,
            // description: '',
            previewImg: '',
            meta_title: '',
            meta_description: '',
            meta_keywords: '',
            profileImg: '',
            // selectedoptions: null,
            // selectedCountry: null,
            name: '',
            image: '',
            checkedCategories:'', 
            // checkedCategoriesc:'',

            options: [
                // {'value': null, text: 'Please select Publish / Unpblish'},
                {value: 1, 'text': 'published'},
                {value: 0, 'text': 'Unpublished'},

            ],

            countryOptions: [
                { value: null, text: 'Please select a country' },
            ]
        }
    },
    created() {
        this.getItem();
        this.getCountries();
    },
    computed: {
        validation() {
            return this.name.length > 4 && this.name.length < 13
        },
    },
    methods: {
        change(e) {
              this.checkedCategories = e.target.value;
            //   alert(checkedCategories);
          },

        getItem()
            {
                var id = this.$route.params.id;

                db.collection('categories').doc(id).get().then((doc) => {
                    console.log(doc.data());
                    this.item = doc.data();
                    this.selc = doc.data().checkedCategories;
                    this.selectedFlavor =  parseInt(doc.data().checkedCategories);
                    this.previewImg = doc.data().image;
                })
                console.log(doc.id, " => ", doc.data())
            },

            getCountries() {
            this.countries = [];

            db.collection("countries")
                .get()
                .then((querySnapshot) => {
                    //console.log('hhhh1');
                    querySnapshot.forEach((doc) => {
                        this.countryOptions.push({
                            value: doc.id,
                            text: doc.data().country,
                            slug: doc.data().slug,
                        });
                        console.log(doc.id, " => ", doc.data());

                    });


                })
                .catch((error) => {
                    console.log("Error getting documents: ", error);
                });
        },

        reset() {
            this.name = '',
            this.selectedFlavor = '',
            this.description = '',
            this.previewImg = '',
            this.meta_title = '',
            this.meta_description = '',
            this.meta_keywords = '',
            this.profileImg = '',
            this.selectedoptions= null,
            this.selectedCountry = null;
        },

        validationForm() {
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    // eslint-disable-next-line
                    // alert('form submitted!')
                    this.submit();
                }
            })
        },

        submit() {
            store.dispatch('app/commitActivateLoader');
             var date = new Date().toLocaleString();//.toISOString().slice(0, 10);
             var id = this.$route.params.id;
                var  date = new Date().toLocaleString();//.toISOString().slice(0, 10);
                 
                if(this.checkedCategories == undefined){
                  this.checkedCategories = this.selc;
                    alert(this.checkedCategories);
                }

                db.collection("categories")
                .doc(id)
                .update({
                  name: this.item.name,
                  description: this.item.description,
                  type: parseInt(this.item.type),
                  meta_title: this.item.meta_title,
                  checkedCategories:parseInt(this.item.type),
                  meta_description: this.item.meta_description,
                  meta_keywords: this.item.meta_keywords,
                  image:this.previewImg,
                  modified: date,
                })
                                .then((doc) => {
                                    console.log("Added Successfully!");
                                    const filename = this.profileImg.name;
                                    if(filename){
                                    const ext = filename.slice(filename.lastIndexOf('.'));
                                    const storageRef = storage.ref();
                                    const fileRef = storageRef.child('categories/' + id + "." + ext);
                                    fileRef.put(this.profileImg).then(result => {
                                        fileRef.getDownloadURL().then(imageUrl => {
                                            console.log(imageUrl);
                                            db.collection("categories").doc(id).update({ image: imageUrl })
                                            store.dispatch('app/commitDeactivateLoader');
                                            this.$router.push({ name: 'categories-list' });
                                            this.showNotification('Category Added Successfully!', 'AlignJustifyIcon', 'success');
                                        }).catch((error) => {
                                            console.error("Error writing document: ", error);
                                            this.showNotification('Error! Request Failed!', 'AlignJustifyIcon', 'danger');
                                        });
                                    });
                                    }
                                    this.$router.push({ name: 'categories-list' });
                                })
                            // var id = localStorage.getItem("userSessionId");
                            // console.log('rrr ' + id);
        },

        showNotification(title, icon, variant) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title,
                    icon,
                    variant,
                },
            })
        },
    },
}
</script>